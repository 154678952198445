import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useUserContext } from '../contexts/UserContext.jsx';
import { useOrganizationContext } from '../contexts/OrganizationContext.jsx';
import Loading from '../components/Loading.jsx';

const ProtectedRoute = ()  =>{
  const { user } = useUserContext();
  const { organization } = useOrganizationContext();
  const url = new URL(window.location.href);

  if (user === '' ) {
    return <Loading />;
  } else if (url.pathname === '/set-password') {
    const hashParams = new URLSearchParams(url.hash.substring(1)); 

    const error = hashParams.get('error');
    const errorCode = hashParams.get('error_code');

    if (error === 'access_denied' && errorCode === 'otp_expired') {
      return <Navigate to="/request-invite" replace />;
    }
  } else if (!user) {
    return <Navigate to="/signin" replace />;
  } else if (!organization) {
    return <Loading />;
  }

  return <Outlet />;
}

export default ProtectedRoute;
