import React from 'react';

export default function ErrorBoundaryFallback({ error }) {
  return (
    <div style={{ padding: '2rem', textAlign: 'center' }}>
      <h1>Something went wrong!</h1>
      <p>We're sorry for the inconvenience. Please try again later.</p>
    </div>
  );
}

